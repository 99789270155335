// Users
export const userLogin = '/user_console/login/'
export const userConsoleRefresh = '/auth/refresh/console/'
export const userAll = '/user_console/all/'
export const userGroup = '/user_console/group/'
export const userSave = '/user_console/register'
export const userPath = '/user_console/'
export const userResetPassword = '/user_console/reset_password/'
export const userChangePassword = '/user_console/change_password/'
export const userValidate = '/user_console/validate/'
export const userValidateDownload = '/user_console/master/'
export const userPing = '/user_console/ping/'

// Communications
export const players = '/circle/comm/'

// Users not used
export const userRefresh = '/auth/refresh/'

// User App
export const userAppAll = '/user/all/'
export const userAppSave = '/user/register'
export const userAppPath = '/user/'
export const userActivity = '/user/activity/'
export const userActivityPaged = '/user/activity/paged/'
export const userNotification = '/user/send_notification/'
export const userAppUpdateAvatar = '/user/register/upload_avatar/'
export const userActivityFile = '/activity_control/file/'
export const userAppPaged = '/user/paged/'
export const userPhoneEmail = '/user/phone_email/'

// Sport
export const sportAllActive = '/sport/all/active/'
export const sportAll = '/sport/all/'
export const sportSave = '/sport/'
export const sportPath = '/sport/'

// Facilities
export const facilityAllActive = '/facility/all/active/'
export const facilityAll = '/facility/all/'
export const facilityByGroup = '/facility/group/'
export const facilityByGroupMin = '/facility/group/min/'
export const facilityPath = '/facility/'
export const facilityUpdateLogo = '/facility/upload_logo/'
export const facilityUpdateImage = '/facility/upload_image/'
export const facilityDeleteImage = '/facility/remove_image/'
export const facilityTimezones = '/facility/timezones/'
export const facilityConnectStatus = '/facility/connect_status/'
export const facilityConnect = '/facility/connect/'
export const facilityConnectLink = '/facility/connect_link/'

// Payment Methods
export const paymentMethodPath = '/payment_method/'
export const paymentMethodAll = '/payment_method/all/'
export const paymentMethodAllMin = '/payment_method/all/min/'
export const paymentMethodFacility = '/payment_method/facility/'
export const paymentMethodGroup = '/payment_method/group/'
export const paymentMethodGroupMin = '/payment_method/group/min/'
export const paymentMethodActivate = '/payment_method/active/'
export const paymentMethodInactivate = '/payment_method/inactive/'
export const paymentMethodDefault = '/payment_method/default/'
export const deletePaymentMethod = '/payment_method/'

export const onSitePaymentsByFacility = '/onsite_payment_method/facility/'
export const onSitePaymentsActiveByFacility = '/onsite_payment_method/active/facility/'
export const onsitePaymentMethodPath = '/onsite_payment_method/'

// Order Videos
export const orderVideoDate = '/video_order/field/date/'
export const orderVideoImport = '/video_order/import/'

// Videos
export const videoOrder = '/video/order'
export const videoOrderEdit = '/video/edit/start_time/'
export const videoPlayback = '/video/playback/'
export const videoPath = '/video/'
export const videoAll = '/video/all/'
export const videosByFacility = '/video/facility/'
export const videosPreorder = '/video/preorder/'
export const videosPreorderGroup = '/video/preorder/group/'
export const videoTransactionCancel = '/video/transaction/cancel/'
export const videoTransactionEdit = '/video/transaction/edit/'
export const videoPaged = '/video/paged/'
export const videoUserViews = '/video/user/views/'

// Fields
export const fieldAll = '/field/all/'
export const fieldByFacility = '/field/facility/'
export const fieldAllByFacility = '/field/facility/all/'
export const fieldPath = '/field/'
export const fieldApp = '/field/code/'
export const fieldUpdateImage = '/field/upload_image/'
export const fieldDeleteImage = '/field/remove_image/'

// Fields not used
export const fieldAllActive = '/field/all/active/'
export const fieldNoCamera = '/field/active/no_camera/'

// Chunks
export const chuckOrderVideo = '/chunk/order_video/field/date/'
export const chuckMinutesOrderVideo = '/chunk/order_video/field/date/minutes/'
export const chuckFieldPath = '/chunk/field/'
export const chuckMinutesAll = '/chunk/all_day/minutes/'
export const chunksActivityControl = '/chunk/activity_control/'

// Fields not used

// Transactions
export const transactionPath = '/transaction/'
export const transactionAll = '/transaction/all/'
export const transactionPaged = '/transaction/paged/'
export const transactionsByFacility = '/transaction/transaction/'
export const transactionsGroupSummary = '/transaction/summary/group/'
export const transactionsMonthly = '/transaction/group/monthly/'

// Payments
export const paymentAll = '/payment/all/'
export const paymentPaged = '/payment/paged/'
export const paymentUserAppPath = '/payment/user_app/'
export const paymentUserAppPaged = '/payment/user_app/paged/'
export const paymentsByFacility = '/payment/facility/'
export const paymentsByGroup = '/payment/group/'
export const paymentPath = '/payment/'

// Cameras
export const cameraAll = '/camera/all/'
export const cameraFacility = '/camera/facility/'
export const cameraPath = '/camera/'
export const cameraThumbnails = '/camera/thumbnails/'
export const cameraStatus = '/camera/status/'

// Cameras not used
export const cameraAllActive = '/camera/all/active/'

// Highlights
export const highlightPath = '/highlight/'
export const highlightAll = '/highlight/all/'
export const highlightPaged = '/highlight/paged/'

// Groups
export const groupPath = '/group/'
export const groupAll = '/group/all/'
export const groupAllActive = '/group/all/active/'
export const groupUpdateLogo = '/group/upload_logo/'
export const groupByFacility = '/group/facility/'

// Players Groups
export const playersGroupPath = '/circle/'
export const playersGroupAll = '/circle/all/'
export const playersGroupByGroup = '/circle/group/'
export const playersGroupByFacility = '/circle/facility/'
export const playersGroupLogo = '/circle/upload_logo/'
export const playersGroupLogoSearch = '/circle/organizer/search/'

// Roles
export const rolePath = '/console_role/'
export const roleAll = '/console_role/all/'
export const permissionAll = '/console_permission/all/'

// Console Notifications
export const consoleNotificationPath = '/console_notification/'
export const consoleNotificationUser = '/console_notification/console_user/'

// Recurring Orders
export const recurringOrderPath = '/recurring_order/'
export const recurringOrderAll = '/recurring_order/all/'
export const recurringOrderGroup = '/recurring_order/group/'
export const recurringOrderPaged = '/recurring_order/paged/'
export const recurringOrderVideos = '/recurring_order/order_video/'

// Dashboard
export const dashboardGroupOrderSummary = '/group/dashboard/order_summary/'
export const dashboardOrderSummary = '/dashboard/order_summary/'
export const dashboardHighlightSummary = '/group/dashboard/highlight_summary/'
export const dashboardActives = '/group/dashboard/facility/field/camera/active/'
export const dashboardUser = '/group/dashboard/user/app_use/'
export const dashboardUserFacility = '/group/dashboard/facility/users/'
export const dashboardVideoViews = '/group/dashboard/video/views/'
export const dashboardFacilitySales = '/group/dashboard/facility/sales/'
export const dashboardFacilityRevenue = '/group/dashboard/facility/revenue/'
export const dashboardUserRegistered = '/group/dashboard/user/registered/'

// Reservations
export const reservationPath = '/reservation/'
export const reservationDetail = '/reservation/detail/'
export const reservationWeekRange = '/reservation/week_range/'
export const reservationFacilityWeekRange = '/reservation/facility/week_range/'
export const reservationFacility = '/reservation/facility/'
export const reservationAddPlayer = '/reservation/add_player/'
export const reservationRemovePlayer = '/reservation/remove_player/'
export const reservationMarkAllPaid = '/reservation/mark_all_paid/'
export const reservationMarkAsPaid = '/reservation/mark_as_paid/'
export const reservationCancel = '/reservation/cancel/'
export const reservationNoReason = '/reservation/no_reason/facility/'
export const reservationPos = '/reservation/pos/facility/'
export const reservationPosAmount = '/reservation/pos/amount/user/'
export const reservationPosProducts = '/reservation/pos/products/'
export const reservationConfirm = '/reservation/confirm/'
export const reservationByFacility = '/reservation/facility/'
export const reservationAvailabilityTime = '/reservation/availability_time/'
export const reservationPaged = '/reservation/paged'
export const recalculatePayment = '/console/reservation/recalculate_payment/'
export const reservationSelectedMarkAsPaid = '/console/reservation/mark_as_paid/'
export const reservationChangePlayersQty = '/console/reservation/players_qty/'

// Recurring Reservations
export const recurringReservationPath = '/recurring_reservation/'
export const recurringReservation = '/recurring_reservation/reservation/'
export const recurringfacility = '/recurring_reservation/facility/'
export const recurringCancel = '/recurring_reservation/cancel/'
export const recurringChangeEndDate = '/recurring_reservation/change_end_date/'
export const recurringVerify = '/recurring_reservation/verify/'

// POS
export const posProducts = '/pos/products/'

// Match
export const matchAddGuest = '/match/add_guest/'
export const matchAddUser = '/console/match/user/add/'
export const matcRemoveUser = '/console/match/user/remove/'

// Metrics
export const metrics = '/circle/facility/metrics/'
export const metricspicks = '/user/pickup/availability/'
export const metricsinsights = '/reservation/facility/insight/'

// Products
export const productPath = '/product/'
export const productImage = '/product/upload_image/'
export const productSummary = '/product/summary/'
export const productFacility = '/product/facility/'

// Product Category
export const productCategoryByFacility = '/product_category/facility/'
export const productCategory = '/product_category/facility/'
export const productCategoryPath = '/product_category/'

// Inventory
export const createInventoryPath = '/product/adjustment/'
export const inventoryEditPath = '/product/current_stock/facility/'
export const inventoryPath = '/product/adjustment/facility/'

// Adjustments
export const adjustmentPath = '/adjustment/'
export const adjustmentFacility = '/adjustment/facility/'

// Terminal
export const terminalPath = '/terminal/'
export const terminalInitiate = '/terminal/initiate_payment/'
export const terminalSimCardTap = '/terminal/sim_card_tap/'
export const terminalCapture = '/terminal/capture_payment/'
export const terminalPaymentIntent = '/terminal/payment_intent/'
export const terminalReaders = '/terminal/readers/'
export const terminalFacility = '/terminal/facility/'
export const terminalFacilityActive = '/terminal/facility/active/'

// Invoice
export const invoicePos = '/invoice/pos/'
export const invoicePosPaymentDeclined = '/invoice_payment/pos/declined/'
export const invoiceOpenTabs = '/invoice/open_tabs/'
export const invoiceOpenTab = '/invoice/open_tab/'
export const invoiceInitiatePayment = '/invoice/pos/manual/initiate_payment/'
export const invoicePaymentPaged = '/invoice_payment/paged/'
export const invoicePaymentPath = '/invoice_payment/'
export const invoicePaymentAgents = '/invoice_payment/user_console/facility/'
export const invoicePosOpenTab = '/invoice/pos/open_tab/'

// Contact
export const contactPath = '/contact/'
export const contactByFacility = '/contact/facility/'
export const contactCategoryByFacility = '/contact_category/facility/'
export const contactCategoryPath = '/contact_category/'
export const contactCategoryActiveByFacility = '/contact_category/facility/active/'

// Blocked Time
export const blockedTimePath = '/blocked_time/'
export const blockedTimeFacility = '/blocked_time/facility/'

export const recurringEventPath = '/recurring_event/'

// Cancellation Reason
export const cancellationReasonPath = '/cancellation_reason/'
export const cancellationReasonGroup = '/cancellation_reason/group/'
export const cancellationReasonActiveGroup = '/cancellation_reason/active/group/'
export const cancellationReasonActiveFacility = '/cancellation_reason/active/facility/'

// Ranking Group
export const rankingGroupPath = '/ranking_group/'

// No consumidas, atento al ultimo /
export const appPath = '/v2/app'
export const userWeb = '/web_user'
export const userWebReset = '/web_user/password/'
export const userDelete = '/user/'

export const savePaymentMethod = '/payment_method'
export const updatePaymentMethod = '/payment_method/'
