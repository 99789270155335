/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
/* eslint-disable import/no-cycle */
import axios from 'axios'
import { asyncLoading } from 'vuejs-loading-plugin'
import { error as toasdError } from '@core/utils/toasted'
import useCryptoJs from '@core/utils/useCryptoJs'
import { getI18nLanguage } from '@/plugins/i18n'
import themeConfig from '@themeConfig'
import store from '@/store'
import { userConsoleRefresh } from './config/apiRoute'
import router from '../router'

// import { getCurrentInstance } from '@vue/composition-api'
// import { useUtils } from '@core/libs/i18n'
// import store from '@/store'

export const API_URL_BACKEND = process.env.VUE_APP_API_URL_BACKEND

function clearLocalStorage(reauth = false) {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('notifications')
  localStorage.removeItem('config-organization')
  localStorage.removeItem('config-facility')
  localStorage.removeItem('config-obj-facility')
  localStorage.removeItem('config-filters')

  // Remove userData & Ability from localStorage
  localStorage.removeItem('userData')
  localStorage.removeItem('userAbility')
  localStorage.removeItem('materio-active-theme')
  localStorage.removeItem('materio-skin-variant')

  // Reset ability
  // vm.$ability.update(initialAbility)

  // Redirect to login page
  router.push({ name: 'auth-login', params: { reauth } })
}

axios.interceptors.request.use(
  config => config, // Do something before request is sent

  error => Promise.reject(error), // Do something with request error
)

axios.interceptors.response.use(
  response => {
    // Do something before response is sent
    const consoleVersion = response.headers['console-version']
    const { reauth, logout } = response.headers
    const { version } = themeConfig.app
    const isLogout = (logout === 'true')
    const isTrueSet = (reauth === 'true')
    if (isLogout || (isTrueSet && version !== consoleVersion)) {
      clearLocalStorage(isTrueSet)
    }

    if (version !== consoleVersion) {
      // window.location.reload(true)
      store.dispatch('app/toggleUpdate', true)
    }

    return response
  },
  async error => {
    // if the system detects user is not logged, itll log out him
    if (
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      error.response?.data &&
      error.response.data.code === 'T002' ||
      error.response.data.code === 'T004' ||
      error.response.data.code === 'E004'
    ) {
      if (error.response.data.code === 'E004') toasdError(error.response.data.message)
      clearLocalStorage()
    }

    if (
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      error.response?.data &&
      error.response.data.code === 'E500' &&
      error.response.data.message !== 'Not enough segments'
    ) {
      toasdError(error.response.data.message)
    }

    if (
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      error.response?.data &&
      error.response.data.code === 'T001'
    ) {
      /**
       * Do request to refresh token
       */
      const originalRequest = error.response.config
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true

      const { userData } = useCryptoJs()
      const refreshToken = userData.value.refresh_token
      try {
        const response = await axios.post(`${API_URL_BACKEND}v1${userConsoleRefresh}`, null, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'el-token': `Bearer ${refreshToken}`,
            'Accept-Language': getI18nLanguage(),
          },
        })

        const { data } = response

        // store.commit('auth/LOGIN_USER', data.data)
        if (data.ok === 1) {
          localStorage.setItem('accessToken', data.data.access_token)
          originalRequest.headers['el-token'] = `Bearer ${data.data.access_token}`

          // router.push('/')

          return axios(originalRequest)
        }

        clearLocalStorage()
      } catch (e) {
        /**
         * if refresh token expired go to Login pages
         */
        if (e.data && (e.data.code === 'T001' || e.data.code === 'T002')) {
          clearLocalStorage()
        }
      }
    }

    // if (error.response.status === 401) {
    //   // store.dispatch('logoutAction')
    // }

    // Do something with response error
    return Promise.reject(error)
  },
)

function buildErrorMessage(error) {
  const errorResponse = {
    ok: 0,
    data: Object,
    message: {
      code: String,
      text: String,
    },
  }

  // if (typeof error.response === 'undefined') {
  //   errorResponse.message.text = t('message.apiErrorUndefined')
  // } else if (error.response.status === 401) {
  //   errorResponse.message.text = t('message.apiError401')
  // } else if (error.response.status === 404) {
  //   errorResponse.message.text = t('message.apiError404')
  // } else if (error.response.status === 500) {
  //   errorResponse.message.text = t('message.apiError500')
  // } else if (error.response.status === 405 || error.response.status === 406) {
  //   errorResponse.message.text = t('message.apiError405_406')
  // } else errorResponse.message.text = error.response.data.message.text
  errorResponse.ok = 0
  errorResponse.message.text = error.response.data.message
  errorResponse.message.code = error.response.data.code
  errorResponse.data = error.response.data.data || null

  // errorResponse.message.code = 'E999'

  return errorResponse
}

export const apiHttp = async (method, endpoint, data, options = {}, loading = true, version = 'v1') => {
  const { accessToken } = localStorage
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'el-token': `Bearer ${accessToken}`,
    'Accept-Language': getI18nLanguage(),
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!options.hasOwnProperty('headers')) {
    // eslint-disable-next-line no-param-reassign
    options.headers = defaultHeaders
  }

  let serviceResponse = {}

  // eslint-disable-next-line no-param-reassign
  method = method.toLowerCase()
  const servicePromise = axios({
    method,
    url: `${API_URL_BACKEND}${version}${endpoint}`,
    data,
    ...options,
  })

  try {
    if (loading) {
      asyncLoading(servicePromise).then().catch()
    }
    const [materializedPromise] = await Promise.all([servicePromise])
    serviceResponse = { ...materializedPromise.data }
  } catch (error) {
    serviceResponse = buildErrorMessage(error)
  }

  return serviceResponse
}
