import { loginApi } from '@api'
import { error } from '@core/utils/toasted'
import useCryptoJs from '@core/utils/useCryptoJs'

// eslint-disable-next-line object-curly-newline
import { LOGIN_USER, LOGOUT_USER, UPDATE_PERSON, REFRESH_SESSION } from '../mutation-types'
import router from '../../router'

const { decryptData } = useCryptoJs()

export default {
  namespaced: true,
  state: {
    user: {},
    logged: false,
    accessToken: null,
    refreshToken: null,
  },
  getters: {
    user: state => state.user,
    accessToken: state => state.token,
    refreshToken: state => state.refreshToken,
  },
  mutations: {
    [LOGIN_USER](state, payload) {
      state.user = payload
      state.logged = true
      state.accessToken = payload.access_token
      state.refreshToken = payload.refresh_token
    },
    [LOGOUT_USER](state) {
      state.user = {}
      state.logged = false
      state.accessToken = null
      state.refreshToken = null
    },
    [UPDATE_PERSON](state, payload) {
      state.user.person = {
        ...state.user.person,
        ...payload,
      }
    },
    [REFRESH_SESSION](state, payload) {
      state.user = {
        ...state.user,
        ...payload,
      }
      state.accessToken = payload.access_token
      state.refreshToken = payload.refresh_token
    },
  },
  actions: {
    async loginAction({ commit }, payload) {
      const serviceResponse = await loginApi(payload)
      if (serviceResponse.ok) {
        const userAbility = [
          {
            action: 'manage',
            subject: 'all',
          },
        ]

        // const userAbility = [
        //   {
        //     action: 'read',
        //     subject: 'ACL',
        //   },
        //   {
        //     action: 'read',
        //     subject: 'Demo',
        //   },
        //   {
        //     action: 'read',
        //     subject: 'Public',
        //   },
        // ]

        // localStorage.setItem('userAbility', JSON.stringify(userAbility))

        // const { user } = response.data
        // const { ability: userAbility } = user

        // Set user ability
        // ? https://casl.js.org/v5/en/guide/intro#update-rules
        // vm.$ability.update(userAbility)

        // Set user's ability in localStorage for Access Control
        localStorage.setItem('userAbility', JSON.stringify(userAbility))
        const user = serviceResponse.data
        const data = decryptData(user.private)
        localStorage.setItem('userData', JSON.stringify(user))
        localStorage.setItem('accessToken', data.access_token)

        // We will store `userAbility` in localStorage separate from userData
        // Hence, we are just removing it from user object
        // delete user.ability

        // Set user's data in localStorage for UI/Other purpose
        // localStorage.setItem('userData', JSON.stringify(user))

        commit(LOGIN_USER, serviceResponse.data)

        // On success redirect to home
        router.push('/')
      } else {
        error(serviceResponse.message.text)
        router.push({ name: 'auth-login' })
      }
    },

    async refreshApp({ commit }, payload) {
      commit(REFRESH_SESSION, payload)

      // trycatch in case app reload same route
      try {
        router.push({ name: 'dashboard-crm' }) // dashboard-crm

        // const userData = JSON.parse(localStorage.getItem('userData'))
        // const userRole = userData && userData.role ? userData.role : null
        // if (userRole === 'admin') return { router.push({ name: 'dashboard-crm' }) }
        // if (userRole === 'client') return { router.push({ name: 'page-access-control' }) }
      } catch (e) {
        // error case
      }
    },

    logoutAction({ commit }) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')
      localStorage.removeItem('config-organization')
      localStorage.removeItem('config-facility')
      localStorage.removeItem('config-obj-facility')
      localStorage.removeItem('config-filters')
      localStorage.removeItem('notifications')
      commit(LOGOUT_USER)

      // router.push({ name: 'auth-login' })
    },
  },
}
