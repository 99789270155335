<template>
  <layout-blank>
    <app-card-update />
    <slot></slot>
  </layout-blank>
</template>

<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue'
import { mdiArrowCollapseDown } from '@mdi/js'
import store from '@/store'
import AppCardUpdate from '@core/components/app-card-update/AppCardUpdate.vue'

export default {
  components: {
    LayoutBlank,
    AppCardUpdate,
  },
  setup() {
    const onUpdate = () => {
      store.dispatch('app/toggleUpdate', false)
    }

    return {
      onUpdate,

      icons: {
        mdiArrowCollapseDown,
      },
    }
  },
}
</script>

<style>
</style>
