export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    configOrganization: localStorage.getItem('config-organization')
      ? parseInt(localStorage.getItem('config-organization'), 10)
      : 0,
    configFacility: localStorage.getItem('config-facility')
      ? parseInt(localStorage.getItem('config-facility'), 10)
      : 0,
    configObjFacility: localStorage.getItem('config-obj-facility')
      ? JSON.parse(localStorage.getItem('config-obj-facility'))
      : {
        id: null,
        name: null,
        time_format: null,
        is12Hour: true,
      },
    configFilters: localStorage.getItem('config-filters')
      ? localStorage.getItem('config-filters')
      : null,
    showUpdate: false,
  },
  getters: {},
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value
    },
    UPDATE_ORGANIZATION(state, value) {
      state.configOrganization = value
    },
    UPDATE_FACILITY(state, value) {
      state.configFacility = value
    },
    UPDATE_OBJ_FACILITY(state, value) {
      state.configObjFacility = value
    },
    UPDATE_FILTERS(state, value) {
      state.configFilters = value
    },
    CLEAR_CONFIG(state) {
      state.configOrganization = 0
      state.configFacility = 0
      state.configObjFacility = {
        id: null,
        name: null,
        time_format: null,
        is12Hour: true,
      }
    },
    TOGGLE_UPDATE(state, value) {
      state.showUpdate = value !== undefined ? value : !state.showUpdate
    },
  },
  actions: {
    async clearConfigAction({ commit }) {
      commit('CLEAR_CONFIG')
    },
    toggleUpdate({ commit }, value) {
      commit('TOGGLE_UPDATE', value)
      localStorage.removeItem('videoData')
      localStorage.removeItem('logData')

      if (!value) window.location.reload(true)
    },
  },
}
